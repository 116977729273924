<template>
  <div class="sys_notice_wrapper">
    <header-box
      @changeMenuIndex="changeHeaderIndex"
      :user="user"
      :meunCurrentIndex="headerIndex"
      @handleLogout="handleLogout"
      @goToPurchase="goToPurchase"
      @goRegister="goRegister"
    ></header-box>
    <div class="announ_wrapper">
      <div class="left">
        <div class="title">系统公告</div>
        <ul>
          <li
            v-for="item in noticeTypeArr"
            :key="item.value"
            @click="changeMenuIndex(item.value)"
            :class="meunIndex == item.value ? 'active' : ''"
          >
            {{ item.name }}
            <a-icon type="right" class="icon" />
          </li>
        </ul>
      </div>
      <div class="right">
        <div class="announ_list" v-if="noticeList">
          <!-- 第一条 -->

          <div class="announ_item" v-if="noticeList.length > 0">
            <!-- new  第一页 -->
            <div class="announ_title title_new" v-if="page.pageNum == 1">
              <div class="new_tag">NEW</div>
              {{ noticeList[0].title }}
            </div>
            <!-- 不是第一页 -->
            <div class="announ_title" v-else>
              <img src="@/assets/notice_icon.png" alt="" class="notice_icon" />
              {{ noticeList[0].title }}
            </div>

            <div class="announ_info">
              <p>尊敬的客户您好：</p>
              <div v-html="noticeList[0].content"></div>
            </div>
            <div class="announ_desc">
              <div class="desc_time">
                发布时间：{{ noticeList[0].createTime }}
              </div>
              <div class="origin">{{ baseData.systemTitle }}</div>
            </div>
          </div>
          <!-- 第二条之后 -->
          <div
            class="announ_item"
            v-for="(item, index) in noticeList"
            :key="item.id"
            v-show="noticeList.length > 0 && index > 0"
          >
            <div class="announ_title">
              <img src="@/assets/notice_icon.png" alt="" class="notice_icon" />
              {{ item.title }}
            </div>
            <div class="announ_info">
              <p>尊敬的客户您好：</p>
              <div v-html="item.content"></div>
            </div>
            <div class="announ_desc">
              <div class="desc_time">发布时间：{{ item.createTime }}</div>
              <div class="origin">{{ baseData.systemTitle }}</div>
            </div>
          </div>
        </div>

        <a-empty
          v-if="noticeList && noticeList.length < 1"
          style="margin-top: 200px"
        />
        <a-pagination
          v-if="noticeList && noticeList.length > 0"
          class="pagin"
          :current="page.pageNum"
          @change="pageChange"
          :defaultCurrent="1"
          :defaultPageSize="page.pageSize"
          :total="page.total"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { sipUser } from "@/api";
import { formatDate, noticeType, jsonToArray } from "@/utils/util";
import { noticeListGet } from "@/self";
import Vue from "vue";
import HeaderBox from "@/components/HeaderBox/index";
export default {
  data() {
    return {
      baseData: baseData,
      headerIndex: 4,
      meunIndex: "all",
      noticeTypeArr: jsonToArray(noticeType),
      noticeList: null,
      page: { pageNum: 1, pageSize: 3, total: 0 },
      total: 0,
      new_tag: false,
      queryParams: {},
      user: {},
      showUserMeun: false,
      query: "",
      inviterCode: Vue.ls.get("DEFAULT_INVITE_KEY")
    };
  },
  mounted() {
    let obj = {
      name: "全部公告",
      value: "all"
    };
    this.noticeTypeArr.unshift(obj);
    this.getNoticeListData();
    //获取用户
    sipUser().then(res => {
      this.user = res.data;
    });
  },
  methods: {
    ...mapActions(["Logout"]),
    //公告列表查询
    getNoticeListData() {
      let params = {
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize
      };
      noticeListGet(Object.assign(params, this.queryParams)).then(res => {
        if (res.success) {
          this.page = res.data.page;
          this.noticeList = res.data.list;
          this.noticeList.forEach((item, index) => {
            item.createTime = formatDate(item.createTime);
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    changeMenuIndex(val) {
      this.meunIndex = val;
      this.page.pageNum = 1;
      if (val == "all") {
        delete this.queryParams.type;
        this.getNoticeListData();
      } else {
        this.queryParams.type = val;
        this.getNoticeListData();
      }
    },
    //顶部导航
    changeHeaderIndex(item) {
      if (item.path.indexOf("static.wodh.cn") > -1) {
        window.open(item.path);
      } else {
        setTimeout(() => {
          this.$router.push({ path: item.path });
        }, 200);

        this.meunCurrentIndex = item.index;
      }
    },

    pageChange(page) {
      this.page.pageNum = page;
      //查询
      this.getNoticeListData();
    },
    goToPurchase() {
      this.$router.push({ path: "/goods/purchase", query: { ...this.query } });
    },
    handleLogout() {
      const that = this;
      this.$confirm({
        title: "提示",
        content: "真的要注销登录吗 ?",
        onOk() {
          return that
            .Logout({})
            .then(() => {
              window.location.reload();
            })
            .catch(err => {
              that.$message.error({
                title: "错误",
                description: err.message
              });
            });
        },
        onCancel() {}
      });
    },
    goRegister() {
      if (this.inviterCode) {
        this.$router.push({ name: "register", query: { c: this.inviterCode } });
      } else {
        this.$router.push({ name: "register" });
      }
    }
  },
  components: {
    HeaderBox
  }
};
</script>

<style lang="less" scoped>
.sys_notice_wrapper {
  min-width: 800px;
  background: #f6f9fd;
  font-family: PingFang SC;
}
.announ_header {
  background-image: url("~@/assets/bg／顶部导航栏 .png");
  background-color: #001a66; //背景图未加载前的过渡背景色
  box-shadow: 0px 2px 20px #b6c6dd;
  height: 64px;
  display: flex;
  align-items: center;
  padding-right: 24px;
  .top_menu {
    flex: 1;
    display: flex;
    justify-content: center;
    height: 64px;
    .top_menu_item {
      padding: 20px 24px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #ffffff;
      opacity: 0.7;
      &:hover {
        opacity: 1;
        background: rgba(0, 0, 0, 0.15);
      }
      &.active {
        opacity: 1;
        background: rgba(0, 0, 0, 0.15);
      }
    }
  }
}
.announ_wrapper {
  width: 1200px;
  margin: 24px auto;
  display: flex;

  .left {
    background-color: #fff;
    width: 200px;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    .title {
      height: 48px;
      line-height: 48px;
      padding-left: 48px;

      font-size: 16px;
      color: rgba(0, 0, 0, 0.45);
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    }
    ul {
      padding: 0;
      list-style: none;
      li {
        height: 40px;
        padding: 0 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;

        color: rgba(0, 0, 0, 0.45);
        cursor: pointer;
        .icon {
          font-size: 12px;
        }
        &:hover {
          color: rgba(0, 0, 0, 0.85);
        }
      }
      .active {
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  .right {
    flex: 1;
    background: #ffffff;
    height: calc(100vh - 136px);
    overflow: auto;
    .announ_list {
      padding: 8px 40px 32px 40px;
      .announ_item {
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        .new_tag {
          font-size: 14px;
          width: 48px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #f93244;
          color: #fff;
          border-radius: 4px;
          margin-right: 12px;
        }
        .announ_title {
          display: flex;
          align-items: center;
          margin-top: 40px;
          margin-bottom: 16px;
          color: rgba(0, 0, 0, 0.85);
          font-size: 18px;
          font-weight: 500;
          font-family: PingFang SC;
          height: 24px;
        }
        .title_new {
          color: #f93244;
          display: flex;
          align-items: center;
        }
        .notice_icon {
          color: rgba(0, 0, 0, 0.45);
          margin-right: 12px;
          width: 20px;
          height: 20px;
        }

        .announ_info {
          margin-bottom: 16px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
        }
        .announ_desc {
          margin-bottom: 16px;
          display: flex;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.65);
          .desc_time {
            margin-right: 24px;
          }
        }
      }
    }
    .pagin {
      display: flex;
      justify-content: center;
      margin-bottom: 45px;
    }
  }
}
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
  border: 1px solid #eee;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(216, 213, 213);
}
</style>
